import React from "react";
import { Link } from "react-router-dom";
import config from "../../../../config.json";
import "./EventCategoryCard.css";

const EventCategoryCard = ({
  categoryTitle,
  categoryDescription,
  eventTypes,
  bg,
  anchorId,
}) => {
  return (
    <div>
      <h2 id={anchorId} style={{ marginTop: "40px", marginBottom: "40px" }}>
        {categoryTitle}
      </h2>
      <div class="EventCategoryCard-Container" style={{ backgroundColor: bg }}>
        <div class="Outline">
          <h4>Στόχος αυτής της κατηγορίας</h4>
          <p>{categoryDescription}</p>
        </div>
        <h4>Είδη δράσεων</h4>
        <ul>
          {eventTypes.map((eventType) => (
            <div className="EventType-Container">
              <img
                src={eventType.example}
                width={350}
                height={210}
                alt="event type"
              />
              <div className="EventType-Contents">
                <h3 id={eventType.anchorId}>{eventType.title}</h3>
                <p style={{ marginTop: "-10px" }}>{eventType.description}</p>
                <div className="EventType-ButtonGroup">
                  <a
                    className="link"
                    href={config.forms.suggestEvent}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <input
                      className="button-primary"
                      type="submit"
                      value="ΕΝΦΙΑΦΕΡΟΜΑΙ"
                    />
                  </a>
                  <Link className="link" to={`/events?type=${eventType.title}`}>
                    <input
                      className="button-primary"
                      type="submit"
                      value="ΔΕΙΤΕ ΤΙΣ ΔΡΑΣΕΙΣ"
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EventCategoryCard;
