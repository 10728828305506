import React from "react";
import { Link } from "react-router-dom";
import config from "../../../../config.json";
import "./CooperateCard.css";

const CooperateCard = (props) => {
  return (
    <div>
      <h2 style={{ marginTop: "80px", marginBottom: "40px" }}>{props.for}</h2>
      <div
        class="CooperateCard-Container"
        style={{ backgroundColor: props.bg }}
      >
        <h4 id={props.header} style={{ marginBottom: "-5px" }}>
          {props.forHeader}
        </h4>
        <p>{props.forDescription}</p>
        <h4 style={{ marginBottom: "-5px" }}>{props.howHeader}</h4>
        <p>{props.howDescription}</p>
        <h3>
          {props.examples.length
            ? "Σχετικές δράσεις"
            : "Δεν υπάρχουν ακόμα σχετικές δράσεις. Mπορείτε να:"}
        </h3>
        <div class="CooperateExamples-Container">
          {props.examples.map((example) => (
            <Link to={`/events_guide#${example.category_anchor_tag}`}>
              <img
                src={example.category_img}
                style={{ width: "360px", height: "220px", margin: "20px" }}
                alt="Category"
              />
            </Link>
          ))}
          <div
            className={
              props.examples.length === 0 || props.examples.length === 3
                ? "FlexRow-Container"
                : ""
            }
          >
            <a
              className="link"
              href={config.forms.suggestEvent}
              target="_blank"
              rel="noreferrer"
            >
              <div className="Cooperate-Button">ΠΡΟΤΕΙΝΕΤΕ ΔΡΑΣΗ</div>
            </a>
            <a
              className="link"
              href={config.forms.suggestScientist}
              target="_blank"
              rel="noreferrer"
            >
              <div className="Cooperate-Button">ΠΡΟΤΕΙΝΕΤΕ ΟΜΙΛΗΤΗ</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CooperateCard;
