import React from "react";

const Chipset = ({ science }) => {
   let scienceColorPalette = {
      Βιολογία: "#1E5128",
      Νευροεπιστήμη: "#4E9F3D",
      Πληροφορική: "#04293A",
      "Τεχνητή Νοημοσύνη": "#064663",
      Ψυχολογία: "#916BBF",
      "Γνωσιακή Επιστήμη": "#C996CC",
      "Επικοινωνία Επιστήμης": "#444444",
      Γλωσσολογία: "#C85C5C",
   };

   return (
      <div
         style={{
            backgroundColor: scienceColorPalette[science],
            borderRadius: "2px",
            paddingLeft: "10px",
            paddingRight: "10px",
            minWidth: "100px",
            textAlign: "center",
            margin: "5px",
            color: "white",
         }}
      >
         {science}
      </div>
   );
};

export default Chipset;
