import React from "react";
import { firestore, storage } from "../../firebase";
import { ref, uploadBytes } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
   align,
   font,
   fontColor,
   fontSize,
   formatBlock,
   hiliteColor,
   horizontalRule,
   lineHeight,
   list,
   paragraphStyle,
   table,
   template,
   textStyle,
   image,
   link,
} from "suneditor/src/plugins";
import "./CreateEvent.css";

class CreateEvent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         id: uuidv4(),
         title: "",
         category: "",
         content: "",
         date: "",
         image: "",
      };
   }

   addEventToCollection = async (event) => {
      await setDoc(doc(firestore, "events", event.id), event);
   };

   createEvent = () => {
      const storageRef = ref(storage, `events_banners/${this.state.id}`);

      uploadBytes(storageRef, this.state.image).then((snapshot) => {
         let event = {
            id: this.state.id,
            title: this.state.title,
            category: this.state.category,
            content: this.state.content,
            description: this.state.description,
            date: this.state.date,
            image: snapshot.ref.toString(),
         };

         this.addEventToCollection(event);
      });
   };

   handleImageChange = (event) => {
      this.setState({ image: event.target.files[0] });
   };

   handleInputChange = (event) => {
      this.setState({ [event.target.name]: event.target.value });
   };

   handleContentChange = (content) => {
      this.setState({ content: content });
   };

   render() {
      return (
         <div className="Page-Container">
            <div className="createEvent-Container">
               <div className="uploadEventBanner-Area">
                  <input type="file" onChange={this.handleImageChange} />
               </div>
               <div className="eventDateAndCategory-Area">
                  <label for="date">Event date</label>
                  <input
                     name="date"
                     type="date"
                     id="date"
                     value={this.state.date}
                     onChange={this.handleInputChange}
                  />
                  <label for="exampleRecipientInput">Event Category</label>
                  <select
                     name="category"
                     onChange={this.handleInputChange}
                     class="u-full-width"
                     value={this.state.category}
                     id="exampleRecipientInput"
                  >
                     <option value="Interview">Ιδεοθύελλα</option>
                     <option value="Iodcast">After Graduation</option>
                     <option value="Iorkshop">
                        Ο Άνθρωπος πίσω από τον Επιστήμονα
                     </option>
                     <option value="Ither">Παρουσίαση πτυχιακών</option>
                  </select>
                  <input
                     className="button-primary"
                     type="submit"
                     onClick={this.createEvent}
                     value="ΔΗΜΙΟΥΡΓΙΑ"
                  />
               </div>
               <div className="eventContent-Area">
                  <input
                     class="u-full-width"
                     placeholder="Type the events title"
                     value={this.state.title}
                     name="title"
                     onChange={this.handleInputChange}
                     id="exampleMessage"
                     style={{ height: "45px", padding: "10px" }}
                  />
                  <input
                     class="u-full-width"
                     placeholder="Type the events description"
                     maxLength={153}
                     value={this.state.description}
                     name="description"
                     onChange={this.handleInputChange}
                     id="exampleMessage"
                     style={{ height: "45px", padding: "10px" }}
                  />
                  <SunEditor
                     height="100%"
                     onChange={this.handleContentChange}
                     setOptions={{
                        showPathLabel: false,
                        minHeight: "50vh",
                        placeholder: "Type events description",
                        plugins: [
                           align,
                           font,
                           fontColor,
                           fontSize,
                           formatBlock,
                           hiliteColor,
                           horizontalRule,
                           lineHeight,
                           list,
                           paragraphStyle,
                           table,
                           template,
                           textStyle,
                           image,
                           link,
                        ],
                        buttonList: [
                           ["font", "fontSize", "formatBlock"],
                           ["paragraphStyle"],
                           [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                           ],
                           ["fontColor", "hiliteColor"],
                           ["removeFormat"],
                           ["outdent", "indent"],
                           ["align", "horizontalRule", "list", "lineHeight"],
                           ["table", "link", "image"],
                        ],
                        formats: [
                           "p",
                           "div",
                           "h1",
                           "h2",
                           "h3",
                           "h4",
                           "h5",
                           "h6",
                        ],
                        font: [
                           "Arial",
                           "Calibri",
                           "Comic Sans",
                           "Courier",
                           "Garamond",
                           "Georgia",
                           "Impact",
                           "Lucida Console",
                           "Palatino Linotype",
                           "Segoe UI",
                           "Tahoma",
                           "Times New Roman",
                           "Trebuchet MS",
                        ],
                     }}
                  />
               </div>
            </div>
         </div>
      );
   }
}

export default CreateEvent;
