import React from "react";
import { firestore, storage } from "../../firebase";
import { uploadBytes } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
   align,
   font,
   fontColor,
   fontSize,
   formatBlock,
   hiliteColor,
   horizontalRule,
   lineHeight,
   list,
   paragraphStyle,
   table,
   template,
   textStyle,
   image,
   link,
} from "suneditor/src/plugins";
import { ref, getDownloadURL } from "firebase/storage";
import { firebaseDateToHtmlDateFormat } from "../../helper";
import "./EditEvent.css";

class Event extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loaded: false,
         image: null,
         date: "",
         title: "",
         category: "",
         content: "",
      };
   }

   componentDidMount() {
      this.getEvent();
   }

   getEvent = async () => {
      const docRef = doc(firestore, "events", this.props.match.params.eventid);
      const docSnap = await getDoc(docRef);

      let formattedDate = firebaseDateToHtmlDateFormat(docSnap.data().date);

      if (docSnap.exists()) {
         if (docSnap.data().image) {
            getDownloadURL(ref(storage, docSnap.data().image)).then((url) => {
               console.log("Date 1", formattedDate);

               this.setState({
                  category: docSnap.data().category,
                  content: docSnap.data().content,
                  description: docSnap.data().description,
                  date: formattedDate,
                  image: docSnap.data().image,
                  title: docSnap.data().title,
                  imageReadyToBeRendered: url,
                  imageToBeUploaded: null,
                  loaded: true,
               });
            });
         } else {
            this.setState({
               category: docSnap.data().category,
               content: docSnap.data().content,
               date: formattedDate,
               image: docSnap.data().image,
               title: docSnap.data().title,
               imageReadyToBeRendered: null,
               imageToBeUploaded: null,
               description: docSnap.data().description,
               loaded: true,
            });
         }
      } else {
         console.log("No such document!");
      }
   };

   addEventToCollection = async (event) => {
      const eventRef = doc(
         firestore,
         "events",
         this.props.match.params.eventid
      );
      await updateDoc(eventRef, event);
   };

   updateEvent = () => {
      const storageRef = ref(storage, `events_banners/${this.state.title}`);

      if (this.state.imageToBeUploaded) {
         uploadBytes(storageRef, this.state.imageToBeUploaded).then(
            (snapshot) => {
               let event = {
                  category: this.state.category,
                  content: this.state.content,
                  date: this.state.date,
                  image: snapshot.ref.toString(),
                  title: this.state.title,
               };
               this.addEventToCollection(event);
            }
         );
      } else {
         let event = {
            category: this.state.category,
            content: this.state.content,
            date: this.state.date,
            title: this.state.title,
            image: this.state.image,
         };
         this.addEventToCollection(event);
      }
   };

   handleImageChange = (event) => {
      this.setState({ imageToBeUploaded: event.target.files[0] });
   };

   handleChange = (event) => {
      console.log("Date 2", event.target.value);

      this.setState({ [event.target.name]: event.target.value });
   };

   handleContentChange = (content) => {
      this.setState({ content: content });
   };

   removeImage = () => {
      this.setState({ imageReadyToBeRendered: null });
   };

   render() {
      let pageContent = null;
      if (this.state.loaded) {
         pageContent = (
            <div className="Page-Container">
               <div className="editEvent-Container">
                  <div className="editEventBanner-Area">
                     {!this.state.imageReadyToBeRendered ? (
                        <input type="file" onChange={this.handleImageChange} />
                     ) : (
                        <img
                           src={this.state.imageReadyToBeRendered}
                           style={{ width: "500px" }}
                           onClick={this.removeImage}
                           alt="Event"
                        />
                     )}
                  </div>
                  <div className="editeditEventDateAndCategory-Area">
                     <label for="date">Event date</label>
                     <input
                        name="date"
                        type="date"
                        id="date"
                        value={this.state.date}
                        onChange={this.handleChange}
                     />
                     <label for="exampleRecipientInput">Event Category</label>
                     <select
                        name="category"
                        onChange={this.handleChange}
                        class="u-full-width"
                        value={this.state.category}
                        id="exampleRecipientInput"
                     >
                        <option value="Interview">Ιδεοθύελλα</option>
                        <option value="Iodcast">After Graduation</option>
                        <option value="Iorkshop">
                           Ο Άνθρωπος πίσω από τον Επιστήμονα
                        </option>
                        <option value="Ither">Παρουσίαση πτυχιακών</option>
                     </select>
                     <input
                        className="button-primary"
                        type="submit"
                        onClick={this.updateEvent}
                        value="ΑΝΑΝΕΩΣΗ"
                     />
                  </div>
                  <div className="editEventContent-Area">
                     <input
                        class="u-full-width"
                        placeholder="Type the events title"
                        value={this.state.title}
                        name="title"
                        onChange={this.handleChange}
                        id="exampleMessage"
                        style={{ height: "45px", padding: "10px" }}
                     />
                     <input
                        class="u-full-width"
                        placeholder="Type the events description"
                        value={this.state.description}
                        name="description"
                        onChange={this.handleChange}
                        id="exampleMessage"
                        style={{ height: "45px", padding: "10px" }}
                        maxLength={153}
                     />
                     <SunEditor
                        height="100%"
                        defaultValue={this.state.content}
                        onChange={this.handleContentChange}
                        setOptions={{
                           showPathLabel: false,
                           minHeight: "50vh",
                           placeholder: "Type events description",
                           plugins: [
                              align,
                              font,
                              fontColor,
                              fontSize,
                              formatBlock,
                              hiliteColor,
                              horizontalRule,
                              lineHeight,
                              list,
                              paragraphStyle,
                              table,
                              template,
                              textStyle,
                              image,
                              link,
                           ],
                           buttonList: [
                              ["font", "fontSize", "formatBlock"],
                              ["paragraphStyle"],
                              [
                                 "bold",
                                 "underline",
                                 "italic",
                                 "strike",
                                 "subscript",
                                 "superscript",
                              ],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["table", "link", "image"],
                           ],
                           formats: [
                              "p",
                              "div",
                              "h1",
                              "h2",
                              "h3",
                              "h4",
                              "h5",
                              "h6",
                           ],
                           font: [
                              "Arial",
                              "Calibri",
                              "Comic Sans",
                              "Courier",
                              "Garamond",
                              "Georgia",
                              "Impact",
                              "Lucida Console",
                              "Palatino Linotype",
                              "Segoe UI",
                              "Tahoma",
                              "Times New Roman",
                              "Trebuchet MS",
                           ],
                        }}
                     />
                  </div>
               </div>
            </div>
         );
      }
      return pageContent;
   }
}

export default Event;
