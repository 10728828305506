import React, { useEffect, useState } from "react";
import { auth } from "../firebase";

export const AuthContext = React.createContext({
  userDataPresent: false,
  user: null,
});

export const AuthProvider = ({ children }) => {
  let [state, changeState] = useState({
    userDataPresent: false,
    user: null,
    listener: null,
  });

  useEffect(() => {
    if (state.listener == null) {
      changeState({
        ...state,
        listener: auth.onAuthStateChanged((user) => {
          if (user)
            changeState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: user,
            }));
          else
            changeState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: null,
            }));
        }),
      });
    }
    return () => {
      if (state.listener) state.listener();
    };
  }, []);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
