import React from "react";
import { firestore, storage } from "../../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import "./Members.css";

class Members extends React.Component {
  constructor() {
    super();

    this.state = {
      members: null,
    };
  }

  getMembers = async () => {
    const querySnapshot = await getDocs(collection(firestore, "members"));
    let members = [];
    querySnapshot.forEach((doc) => {
      let memberData = {
        name: doc.data().name,
        photo: null,
        quote: doc.data().quote,
        studies: doc.data().studies,
        link: doc.data().link,
      };
      let photoReferenceInStorage = ref(storage, doc.data().photo);
      getDownloadURL(photoReferenceInStorage).then((url) => {
        memberData.photo = url;
        members.push(memberData);
        this.setState({ members: members });
      });
    });
  };

  componentDidMount() {
    this.getMembers();
  }

  render() {
    let pageContent = null;
    if (this.state.members != null) {
      pageContent = (
        <React.Fragment>
          <div className="Members-Container">
            {this.state.members.map((memberData) => (
              <a href={memberData.link} target="_blank" rel="noreferrer">
                <div className="Member-Card">
                  <div
                    className="Member-Photo"
                    style={{
                      backgroundImage: `url("${memberData.photo}")`,
                      height: "200px",
                      width: "200px",
                      backgroundSize: "cover",
                      border: "none",
                      borderRadius: "50%",
                    }}
                  />
                  <h3> {memberData.name} </h3>
                  <p> {memberData.studies} </p>
                  <p className="Member-Quote">
                    {" "}
                    {memberData.quote ? `"${memberData.quote}"` : null}{" "}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </React.Fragment>
      );
    }
    return pageContent;
  }
}

export default Members;
