import React from "react";

import ITVangelisHeart from "../../assets/heart.png";
import "./Footer.css";

const Footer = () => {
   return (
      <div className="Footer-Container">
         <p>Coded with </p>
         <a href="https://vangelisgara.com/" target="_blank" rel="noreferrer">
            <img
               className="zoom"
               src={ITVangelisHeart}
               alt="Vangelis Garaganis"
            />
         </a>
         <p>CogniHub 2020 - {new Date().getFullYear()} </p>
      </div>
   );
};

export default Footer;
