import './App.css';
import './index.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import AboutUs from './Pages/AboutUs/AboutUs';
import { AuthProvider } from './Authorization/AuthProvider';
import BecomeMember from './Pages/BecomeMember/BecomeMember';
import ContactUs from './Pages/ContactUs/ContactUs';
import Cooperate from './Pages/Cooperate/Cooperate';
import CreateEvent from './Pages/CreateEvent/CreateEvent';
import EditEvent from './Pages/EditEvent/EditEvent';
import Event from './Pages/Event/Event';
import Events from './Pages/Events/Events';
import EventsGuide from './Pages/EventsGuide/EventsGuide';
import Faq from './Pages/Faq/Faq';
import Footer from './Components/Footer/Footer';
import GuarderRoute from './Authorization/GuardedRoute';
//import { Navbar, Footer, ScrollToTop, DarkTheme, LightTheme } from "./UI/UI";
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import ManageEvents from './Pages/ManageEvents/ManageEvents';
import MentorHub from './Pages/MentorHub/MentorHub';
import Navbar from './Components/Navbar/Navbar';
import NotFound from './Pages/NotFound/NotFound';
import ScrollToTop from './ScrollToTop';

class App extends React.Component {
   state = {
      theme: "light",
   };

   // componentDidMount() {
   //    // retrieve theme from local storage
   //    const savedTheme = localStorage.getItem("savedTheme");
   //    if (savedTheme !== null) this.setState({ theme: savedTheme });
   // }

   // switchTheme = () => {
   //    var themeSwitched = this.state.theme === "light" ? "dark" : "light";
   //    // store theme to local storage
   //    localStorage.setItem("savedTheme", themeSwitched);
   //    this.setState({ theme: themeSwitched });
   // };

   render() {
      return (
         <AuthProvider>
            <Router>
               <Navbar />
               <ScrollToTop />
               <Switch>
                  <GuarderRoute exact path="/admin" component={ManageEvents} />
                  <GuarderRoute
                     exact
                     path="/events/:eventid/edit"
                     component={EditEvent}
                  />
                  <GuarderRoute
                     exact
                     path="/admin/create_event"
                     component={CreateEvent}
                  />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/" component={Home} />
                  <Route exact path="/aboutus" component={AboutUs} />
                  <Route exact path="/events" component={Events} />
                  <Route exact path="/events/:eventid" component={Event} />
                  <Route exact path="/contactus" component={ContactUs} />
                  <Route exact path="/faq" component={Faq} />
                  <Route exact path="/becomemember" component={BecomeMember} />
                  <Route exact path="/cooperate" component={Cooperate} />
                  <Route exact path="/events_guide" component={EventsGuide} />
                  <Route exact path="/mentorhub" component={MentorHub} />
                  <Route component={NotFound} />
               </Switch>
               <Footer />
            </Router>
         </AuthProvider>
      );
   }
}

export default App;
