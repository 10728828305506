import React from "react";
import Particles from "react-particles-js";

const CustomParticles = () => {
  return (
    <Particles
      params={{
        particles: {
          number: {
            value: 156,
            density: { enable: true, value_area: 9592.758697024597 },
          },
          color: { value: "#5b2361" },
          shape: {
            type: "polygon",
            stroke: { width: 0.1, color: "#000000" },
            polygon: { nb_sides: 2 },
            image: { src: "img/github.svg", width: 100, height: 100 },
          },
          opacity: {
            value: 0.3,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
          },
          size: {
            value: 2.6,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
          },
          line_linked: {
            enable: true,
            distance: 394,
            color: "#5b2361",
            opacity: 0.35255437946329715,
            width: 1.1,
          },
          move: {
            enable: true,
            speed: 5,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "repulse" },
            onclick: { enable: true, mode: "push" },
            resize: true,
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 },
          },
        },
        retina_detect: true,
      }}
      style={{
        fontFamily: "sans-serif",
        textAlign: "center",
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        // position: "fixed",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "100",
      }}
    />
  );
};
export default CustomParticles;
