import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase";
import {
   collection,
   getDocs,
   deleteDoc,
   doc,
   query,
   orderBy,
} from "firebase/firestore";
import { auth } from "../../firebase";
import add from "../../assets/plus.svg";
import edit from "../../assets/edit.png";
import remove from "../../assets/delete.svg";
import "../../skeleton.css";
import "./ManageEvents.css";

class ManageEvents extends React.Component {
   constructor() {
      super();

      this.state = {
         events: [],
      };
   }

   componentDidMount() {
      this.getEvents();
   }

   logout() {
      auth.signOut();
   }

   getEvents = async () => {
      const eventsRef = collection(firestore, "events");

      const q = query(eventsRef, orderBy("date", "desc"));

      const querySnapshot = await getDocs(q);

      let events = [];
      querySnapshot.forEach((doc) => {
         let eventData = {
            title: doc.data().title,
            id: doc.id,
         };
         events.push(eventData);
      });
      this.setState({ events: events });
   };

   deleteEvent = async (docId) => {
      await deleteDoc(doc(firestore, "events", docId));
   };

   render() {
      return (
         <div className="Page-Container">
            <h2>Δράσεις</h2>
            <table className="Admin-Table">
               <thead>
                  <tr>
                     <th style={{ width: "90%" }}>Event</th>
                     <th>Action</th>
                  </tr>
               </thead>
               <tbody>
                  {this.state.events.map((eventData, index) => (
                     <tr>
                        <td>
                           <Link
                              className="link"
                              to={`/events/${eventData.id}`}
                           >
                              <p>{eventData.title}</p>
                           </Link>
                        </td>
                        <td>
                           <img
                              onClick={() => this.deleteEvent(eventData.id)}
                              style={{ width: "26px" }}
                              src={remove}
                              alt="Remove"
                           />
                           <Link to={`/events/${eventData.id}/edit`}>
                              <img
                                 style={{ width: "26px", marginLeft: "20px" }}
                                 src={edit}
                                 alt="Edit"
                              />
                           </Link>
                        </td>
                     </tr>
                  ))}
                  <tr>
                     <td>
                        <Link to="/admin/create_event">
                           <img style={{ width: "26px" }} src={add} alt="Add" />
                        </Link>
                     </td>
                  </tr>
               </tbody>
            </table>
            <p className="hoverable" onClick={this.logout}>
               Αποσύνδεση
            </p>
         </div>
      );
   }
}

export default ManageEvents;
