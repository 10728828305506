import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="Page-Container">
      <div className="NotFoundContainer">
        <h1>Τετρακόσια τέσσερα </h1>
        <p>Η σελίδα δεν βρέθηκε</p>
        <Link className="link" to="/">
          <p>⟶ Πίσω στην αρχική</p>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
