import React from "react";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import openIcon from "../../assets/open.png";
import closeIcon from "../../assets/close.png";
import "./Navbar.css";

const Navbar = () => {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(true);
  return (
    <div className="Navbar-Container">
      <div onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container">
          <Link className="link" to="/">
            <Logo />
          </Link>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                exact
                to="/aboutus"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Ποιοι είμαστε
              </Link>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/events"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Οι δράσεις μας
              </Link>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/becomemember"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Θέλω να γίνω μέλος
              </Link>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/cooperate"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Ας συνεργαστούμε
              </Link>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/contactus"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Επικοινωνία
              </Link>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/faq"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Συχνές Ερωτήσεις
              </Link>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {click ? (
              <img className="nav-img" src={closeIcon} alt="Close" />
            ) : (
              <img className="nav-img" src={openIcon} alt="Open" />
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
