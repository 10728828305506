import React, { useEffect } from "react";
import events_guide_data from "./events_guide.json";
import EventCategoryCard from "./Components/EventCategoryCard/EventCategoryCard";
import "./events_guide.json";
import "./EventsGuide.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const EventsGuide = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="Page-Container">
      <h2 style={{ marginTop: "60px" }}>Οδηγός Δράσεων</h2>
      <p style={{ fontSize: "18px", marginTop: "-10px", padding: "25px" }}>
        Οι δράσεις του Cognihub αφορούν τους παρακάτω άξονες:
      </p>
      {events_guide_data.map((dato) => (
        <EventCategoryCard
          categoryTitle={dato.categoryTitle}
          categoryDescription={dato.categoryDescription}
          eventTypes={dato.eventTypes}
          bg={dato.bg}
          anchorId={dato.anchorId}
        />
      ))}
    </div>
  );
};

export default EventsGuide;
