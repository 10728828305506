import './Introduction.css';
import React from 'react';
import { Link } from 'react-router-dom';

class Introduction extends React.Component {
   constructor() {
      super();
      this.goalAndDetails = [
         {
            goal: "Interdisciplinary thinking.",
            details: "",
         },
         {
            goal: "Αναδεικνύουμε την διεπιστημονικότητα",
            details:
               "Αναδεικνύουμε τη διεπιστημονικότητα μεταξύ αντικειμένων όπως η Ψυχολογία, η Πληροφορική, η Βιολογία, η Γλωσσολογία, η Φιλοσοφία",
         },
         {
            goal: "Απευθυνόμαστε σε ",
            details:
               "Απευθυνόμενοι σε φοιτητές σχετικών αντικειμένων με την Γνωσιακή Επιστήμη, όπως η Φιλοσοφία, η Βιολογία, η Πληροφορική, η Ψυχολογία και η Γλωσσολογία",
         },
         {
            goal: "Συνδέουμε τους φοιτητές με την αγορά εργασίας",
            details:
               "Μέσω συζητήσεων, συνεντεύξεων και εξειδικευμένα events είτε με ανθρώπους που έχουν εμπειρία στη αγορά εργασίας, είτε με φοιτητές που κάνουν τα πρώτα τους βήματα",
         },
         {
            goal: "Στοχεύουμε στην απόκτηση γνώσεων και δεξιοτήτων",
            details:
               "Μέσω δράσεων και σεμιναρίων, επιθυμούμε την απόκτηση ικανοτήτων που θα φανούν χρήσιμες τόσο στη καριέρα όσο και στη ακαδημαική ή ερευνητική πορεία των φοιτητών",
         },
         {
            goal: "Επικοινωνούμε τη γνωσιακή επιστήμη",
            details:
               "Διοργανώνοντας συζητήσεις, παρουσιάσεις και συνεντεύξεις, με ειδικούς ή φοιτητές, σε θέματα που αφορούν το ευρύτερο φάσμα των επιστημών που εμπίπτουν στην γνωσιακή επιστήμη",
         },
      ];
      this.state = {
         currentGoal: 0,
      };
   }

   componentDidMount() {
      let brain_part = document.querySelector("#brainpart_1");

      brain_part.classList.add("blink_it");
   }

   changecurrentGoal = (currentGoal) => {
      this.setState({ currentGoal: currentGoal });
   };

   onBrainPartEnter = (part) => {
      let brain_part = document.querySelector("#brainpart_1");
      brain_part.classList.remove("blink_it");

      this.changecurrentGoal(part);
   };

   onBrainPartLeave = () => {
      this.changecurrentGoal(0);
   };

   render() {
      return (
         <div className="Introduction-Container">
            <div className="GoalsAndBrain-Container">
               <div className="Goals-Container">
                  <div className="GoalsText">
                     <h1>{this.goalAndDetails[this.state.currentGoal].goal}</h1>
                     <p>
                        {this.goalAndDetails[this.state.currentGoal].details}
                     </p>
                  </div>
               </div>
               <div className="Brain-Container">
                  <div className="tdc-main-right-demo-brain">
                     <div
                        id="brainpart_1"
                        onMouseEnter={() => this.onBrainPartEnter(1)}
                        onMouseLeave={() => this.onBrainPartLeave()}
                        className="tdc-brain-part tdc-frontal-lobe"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 263.36 316.37"
                        >
                           <defs>
                              <style>
                                 {`
                                    .cls-1 {
                                        stroke-miterlimit: 10;
                                        stroke-width: 2px;
                                    } `}
                              </style>
                           </defs>
                           <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_2-2" data-name="Layer 2">
                                 <path
                                    className="cls-1"
                                    d="M1.27,192.38c1.08-11.44,4.95-19.83,12.68-36.62A365.14,365.14,0,0,1,32.26,122c1.61-2.63,9.21-15.05,16.9-25.35,24.44-32.73,55.69-52,60.56-54.93,26.78-16.18,50.73-22.39,67.61-26.76,4.64-1.2,12.27-2.39,26.76-5.63,11.63-2.6,17.4-4.09,26.76-5.63,17.17-2.83,25.83-4.16,29.58,0,2.74,3,2.84,8.11-1.41,22.54-7.65,25.95-13.47,28.1-16.9,42.25-5.61,23.14,7.46,27.75,1.41,52.11-4.11,16.57-10.23,14.72-14.08,32.39-4.51,20.66,2.24,30.59-5.63,39.44-5.88,6.6-12.89,4.73-25.35,8.45-22.61,6.74-36.27,23.85-40.85,29.58-21.05,26.36-9.69,48-33.8,70.42-3.76,3.49-8.84,8.11-16.9,11.27-23.36,9.14-50.45-2.41-66.2-15.49-16.14-13.4-22-31.94-33.8-69C1.47,210.48.4,201.55,1.27,192.38Z"
                                 />
                              </g>
                           </g>
                        </svg>
                     </div>
                     <div
                        id="brainpart_2"
                        onMouseEnter={() => this.onBrainPartEnter(2)}
                        onMouseLeave={() => this.onBrainPartLeave()}
                        className="tdc-brain-part tdc-cerebellum"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 214.25 161.76"
                        >
                           <defs>
                              <style>
                                 {`
                                    .cls-1 {
                                        stroke-miterlimit: 10;
                                        stroke-width: 2px;
                                    } `}
                              </style>
                           </defs>
                           <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_2-2" data-name="Layer 2">
                                 <path
                                    className="cls-1"
                                    d="M113.8,157.91c12.66-4.17,35.25-16.66,59.15-46.48,24.13-30.11,32.25-56.24,35.21-67.61,4-15.51,8.74-27.87.68-35.57C202.42,2.13,191.69-1.46,164.5,3c-19.42,3.17-23.72,6.37-36.62,0-9.8-4.84-18.54,4.8-28.17,8.45-25.49,9.68-16.73,7.29-38,16.9-15.87,7.16-20.89,3.28-38,.86-6-.85-17.25,6.69-22,14.49-2.83,4.63,4,6.31,13.56,15.64,10.35,10.05,14.64,21.33,18.31,31,7.08,18.63,2.81,23,8.45,35.21,8.2,17.75,24.74,25.66,29.58,28.17,5.1,2.65,13,6.65,23.94,7A50.93,50.93,0,0,0,113.8,157.91Z"
                                 />
                              </g>
                           </g>
                        </svg>
                     </div>
                     <div
                        id="brainpart_3"
                        className="tdc-brain-part tdc-occipital-lobe"
                        onMouseEnter={() => this.onBrainPartEnter(3)}
                        onMouseLeave={() => this.onBrainPartLeave()}
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 172.75 162.7"
                        >
                           <defs>
                              <style>
                                 {`
                                    .cls-1 {
                                        stroke-miterlimit: 10;
                                        stroke-width: 2px;
                                    } `}
                              </style>
                           </defs>
                           <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_2-2" data-name="Layer 2">
                                 <path
                                    className="cls-1"
                                    d="M28.08,150.3c7.67-2,12.21,1.73,21.13,4.23,20.93,5.86,26-6.07,53.52-4.23,20.17,1.35,23.56,8.16,35.21,4.23,12.82-4.33,19.57-16.23,23.94-23.94,9.56-16.84,9.73-33,9.86-45.07a121,121,0,0,0-7-42.25C160.84,32.67,150.78.61,137.94,1c-5.69.17-11.33,6.67-12.68,12.68-1.67,7.45,4.07,10.52,4.23,18.31.13,6.41-3.64,9.38-21.13,28.17C88.12,81.91,89.29,82.11,83,86.92c-8.35,6.39-12.33,7.5-16.9,14.08-6.33,9.11-4.26,15-8.45,21.13-6.29,9.23-17.31,1.73-38,9.86-8.06,3.16-16.6,6.65-18.31,14.08-1.44,6.25,2.18,14.33,7,15.49C14.3,163,17.55,153,28.08,150.3Z"
                                 />
                              </g>
                           </g>
                        </svg>
                     </div>
                     <div
                        id="brainpart_4"
                        className="tdc-brain-part tdc-parietal-lobe"
                        onMouseEnter={() => this.onBrainPartEnter(4)}
                        onMouseLeave={() => this.onBrainPartLeave()}
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 257.5 215.34"
                        >
                           <defs>
                              <style>
                                 {`
                                    .cls-1 {
                                        stroke-miterlimit: 10;
                                        stroke-width: 2px;
                                    } `}
                              </style>
                           </defs>
                           <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_2-2" data-name="Layer 2">
                                 <path
                                    className="cls-1"
                                    d="M32.14,3c3.32-4.53,11.53-.24,33.8,1.41C81.54,5.58,85,4,94.12,7.24c7.68,2.71,8.41,4.94,16.9,8.45,10.63,4.39,16.22,3.67,26.76,5.63a119.7,119.7,0,0,1,40.85,16.9c9.49,6.35,7.31,7.28,25.35,22.54C219.67,74,221.62,73.57,232.14,83.3c13,12.06,27,24.92,23.94,35.21a12.91,12.91,0,0,1-.66,1.67c-3.92,8.79-10.77,9-13.42,13.82-3.89,7,6.89,13.83,4.23,23.94-1.24,4.7-4.09,5.44-14.08,14.08A265.16,265.16,0,0,0,208.2,196c-10.74,12.43-12.22,17.65-18.31,18.31s-7.11-4.24-16.9-7c-9.21-2.63-17.38-.89-28.17,1.41-14.05,3-14.68,6-22.54,5.63-10.5-.53-18-6.37-22.54-9.86-5.35-4.15-16-12.38-15.49-22.54.09-1.87,1.94-6,5.63-14.08,5.21-11.45,6.73-13.47,5.63-15.49-2.29-4.25-13.1-3.11-19.72-1.41-11.06,2.84-11.6,7.53-23.94,12.68-4.92,2-11.73,3.24-25.35,5.63-21.13,3.71-23,2.39-23.94,1.41-4.43-4.86,1.38-17.38,7-29.58,5.76-12.41,8.88-14.31,11.27-22.54,3.33-11.47.89-22.23,0-26.76-2.46-12.49-6-12.52-5.63-19.72C15.73,61.36,23.46,61,29.33,45.27a60.81,60.81,0,0,0,4.23-26.76C32.77,9,29.94,6,32.14,3Z"
                                 />
                              </g>
                           </g>
                        </svg>
                     </div>
                     <div
                        id="brainpart_5"
                        className="tdc-brain-part tdc-temporal-lobe"
                        onMouseEnter={() => this.onBrainPartEnter(5)}
                        onMouseLeave={() => this.onBrainPartLeave()}
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 287.49 189.89"
                        >
                           <defs>
                              <style>
                                 {`
                                    .cls-1 {
                                        stroke-miterlimit: 10;
                                        stroke-width: 2px;
                                    } `}
                              </style>
                           </defs>
                           <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_2-2" data-name="Layer 2">
                                 <path
                                    className="cls-1"
                                    d="M1.11,140c1-7.82,8.46-7.87,16.9-19.72,5.3-7.43,4.49-10.38,12.68-33.8C36.09,71,38.8,63.26,40.54,61.12,51.26,47.95,63.39,54.13,87,40c14-8.4,10.25-10.88,23.94-18.31C114,20,122.33,15.74,154.63,7.6,169,4,175-1.16,178.57,2c4.19,3.63-1.85,10.92,1.41,21.13,4,12.49,18.43,18.49,28.17,22.54,7.21,3,16.44,6.83,28.17,5.63,14.42-1.47,18.71-9.11,33.8-8.45,4.64.2,12.83.56,15.49,5.63,3.1,5.91-2.4,16.14-9.86,19.72-5.77,2.77-9.19-.12-16.9,0-16.57.26-34.76,14.13-36.62,28.17C221,105.45,227.58,108,225,114.64c-5,13.15-35.37,14-39.44,14.08-12.9.35-14.25-2.39-21.13,0-8.52,3-9.51,8.24-23.94,23.94A184.6,184.6,0,0,1,118,173.8c-9.73,7.54-15.42,12-23.94,14.08-8.91,2.22-15.92.57-29.58-2.82-17.22-4.27-33-8.2-47.89-21.13C9.48,157.73-.12,149.37,1.11,140Z"
                                 />
                              </g>
                           </g>
                        </svg>
                     </div>
                  </div>
               </div>
            </div>

            <h4 className="LearnMore">
               <Link
                  exact
                  to="/aboutus"
                  activeClassName="active"
                  className="nav-links link"
               >
                  Μάθετε Περισσότερα
               </Link>
            </h4>

            <section>
               <a className="scroll-down" />
            </section>

         </div>
      );
   }
}

export default Introduction;
