import React from "react";
import { firestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import "../../skeleton.css";
import logo from "../../assets/logo.png";
import user from "../../assets/user.svg";
import "./Faq.css";

class Faq extends React.Component {
  constructor() {
    super();

    this.state = {
      faq: [],
    };
  }

  componentDidMount() {
    this.getFaq();
  }

  getFaq = async () => {
    const querySnapshot = await getDocs(collection(firestore, "faq"));
    let faq = [];
    querySnapshot.forEach((doc) => {
      faq.push(doc.data());
    });
    this.setState({ faq: faq });
  };

  render() {
    return (
      <div className="Page-Container">
        <h2>Συχνές Ερωτήσεις</h2>
        {this.state.faq.map((questionAndAnswerData, index) => (
          <div className="QuestionAndAnswer-Card">
            <div class="container">
              <img src={user} alt="Avatar" />
              <p>{questionAndAnswerData.question}</p>
            </div>
            <div class="container darker">
              <img src={logo} alt="Avatar" class="right" />
              <p>{questionAndAnswerData.answer}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Faq;
