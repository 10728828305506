import React from "react";
import { Link } from "react-router-dom";
import "./HelpUs.css";

const HelpUs = () => {
  const helpUsBy = [
    {
      youAre: "Είμαι φοιτητής",
      youCanHelpUsBy:
        "Παρουσίασέ μας το ερευνητικό σου έργο ή μίλησέ μας για την εμπειρία σου.",
      anchorId: "stud",
    },
    {
      youAre: "Είμαι ειδικός",
      youCanHelpUsBy:
        "Μιλήστε μας για το αντικείμενό σας και εμείς θα σας προτείνουμε το κατάλληλο είδος δράσης",
      anchorId: "experts",
    },
    {
      youAre: "Είμαι επιχείριση",
      youCanHelpUsBy:
        "Μοιραστείτε μαζί μας τις εμπειρίες και τις συμβουλές σας. Από την πλευρά μας θα φροντίσουμε να συγκεντρώσουμε τις απορίες των φοιτητών.",
      anchorId: "enterprise",
    },
    {
      youAre: "Είμαι οργανισμός",
      youCanHelpUsBy:
        "Θα χαρούμε να εξετάσουμε τρόπους συνεργασίας ή διοργάνωσης κοινών δράσεων",
      anchorId: "org",
    },
  ];

  return (
    <React.Fragment>
      <h2 style={{ marginTop: "80px" }}>Πώς μπορώ να βοηθήσω;</h2>
      <div className="HelpUs-Container">
        {helpUsBy.map((idea) => (
          <Link className="link" to={`/cooperate#${idea.anchorId}`}>
            <div className="HelpUs-Card">
              <h5> {idea.youAre} </h5>
              <p> {idea.youCanHelpUsBy} </p>
            </div>
          </Link>
        ))}
      </div>
      <Link
        className="link"
        to="/cooperate#other"
        style={{ marginTop: "20px", fontSize: "18px" }}
      >
        ⟶ Πώς αλλιώς μπορώ να βοηθήσω;
      </Link>
    </React.Fragment>
  );
};

export default HelpUs;
