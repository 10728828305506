import React, { useEffect } from "react";
import CollabInfo from "./Components/CollabInfo/CollabInfo";
import cooperate_data from "./cooperate.json";
import "./Cooperate.css";
import CooperateCard from "./Components/CooperateCard/CooperateCard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Cooperate = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="Page-Container">
      <h2>Ας συνεργαστούμε</h2>
      <CollabInfo />
      {cooperate_data.map((dato) => (
        <CooperateCard
          header={dato.header}
          for={dato.for}
          forHeader={dato.forHeader}
          howHeader={dato.howHeader}
          forDescription={dato.forDescription}
          howDescription={dato.howDescription}
          examples={dato.examples}
          bg={dato.bg}
        />
      ))}
    </div>
  );
};

export default Cooperate;
