import React from "react";
import date from "../../../../assets/date.png";
import { Link } from "react-router-dom";
import { firebaseDateToString } from "../../../../helper";
import Tooltip from "../../../../Components/Tooltip/Tooltip";
import atom from "../../../../assets/atom.png";
import "./Events.css";

const Events = (props) => {
   var eventsContent = null;
   if (props.events) {
      eventsContent = (
         <React.Fragment>
            <div className="EventsInHomePage-Container">
               {props.events.map((event) => (
                  <Link className="link" to={`/events/${event.id}`}>
                     <div className="Event-Card">
                        <div
                           className="Event-Preview"
                           style={{
                              backgroundImage: `url(${event.image})`,
                              backgroundSize: "cover",
                           }}
                        />
                        <div className="Event-Data">
                           <h3>{event.title}</h3>
                           <p style={{ marginTop: "-10px" }}>
                              {event.description}
                           </p>
                           <div
                              style={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                                 marginTop: "-20px",
                                 width: "92%",
                              }}
                           >
                              <div style={{ display: "flex" }}>
                                 <img
                                    style={{
                                       paddingTop: "23px",
                                       marginRight: "10px",
                                    }}
                                    src={date}
                                    width={20}
                                    height={21}
                                    alt="Date"
                                 />
                                 <p
                                    style={{
                                       fontSize: "18px",
                                    }}
                                 >
                                    {firebaseDateToString(event.date)}
                                 </p>
                              </div>

                              <Tooltip
                                 title={event.sciences?.join(", ")}
                                 position="left"
                              >
                                 <span>
                                    <img
                                       src={atom}
                                       width={30}
                                       height={31}
                                       alt="Date"
                                    />
                                 </span>
                              </Tooltip>
                           </div>
                        </div>
                     </div>
                  </Link>
               ))}
            </div>
            <Link
               className="link"
               to="/events"
               style={{ marginTop: "20px", fontSize: "18px" }}
            >
               ⟶ Δείτε όλες τις δράσεις μας
            </Link>
         </React.Fragment>
      );
   }
   return (
      <React.Fragment>
         <h2 style={{ marginTop: "80px", marginBottom: "40px" }}>
            Πρόσφατες δράσεις μας
         </h2>
         {eventsContent}
      </React.Fragment>
   );
};

export default Events;
