import React from "react";
import logo from "../../assets/logo.png";

import "./Logo.css";

const Logo = () => {
  return (
    <div className="imgAndText">
      <img className="logo-medium" src={logo} alt="logo" />
      <h3>CogniHub</h3>
    </div>
  );
};

export default Logo;
