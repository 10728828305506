import React from "react";
import { useState } from "react";
import axios from "axios";
import "./BecomeMember.css";
import config from "../../config.json";

const BecomeMember = () => {
   const [participantInfo, setParticipantInfo] = useState({
      date: new Date().toISOString().slice(0, 10),
      name: "",
      email: "",
      reason: "",
      communicationWithCognihub: null,
   });

   const handleChange = (e) => {
      setParticipantInfo({
         ...participantInfo,
         [e.target.name]: e.target.value,
      });
   };

   const onSubmit = (e) => {
      e.preventDefault();
      axios
         .post(
            config.googleSheets.excelForPotentialMembers.url,
            participantInfo
         )
         .then((response) => {
            console.log(response);
            setParticipantInfo({
               communicationWithCognihub: response.status === 200,
            });
         });
   };

   var CommunicationWithCognihub;
   if (participantInfo.communicationWithCognihub == null) {
      CommunicationWithCognihub = null;
   } else if (participantInfo.communicationWithCognihub === false) {
      CommunicationWithCognihub = (
         <h3>
            Ευχαριστούμε για το ενδιαφέρον σας, αλλά δεν μπορέσαμε να
            καταγράψουμε το αίτημα σας!
         </h3>
      );
   } else {
      CommunicationWithCognihub = (
         <h3>
            Ευχαριστούμε για το ενδιαφέρον σου! Θα σε ειδοποιήσουμε στο
            επόμενο Open Call μας!
         </h3>
      );
   }

   return (
      <div className="Page-Container">
         <div className="BecomeMember-Container">
            <h2>Θέλω να γίνω μέλος</h2>
            <p style={{ marginTop: "-30px" }}>
               Νέα μέλη γίνονται σε συγκεκριμένα χρονικά διαστήματα, μέσω open calls. 
               Μπορείς να δηλώσεις το ενδιαφέρον σου στην παρακάτω φόρμα, 
               και εμείς να σε βάλουμε σε σειρά προτεραιότητας στο επόμενό μας open call.
            </p>
            <form onSubmit={onSubmit}>
               <h3>Φόρμα Δήλωσης Ενδιαφέροντος</h3>
               <div>
                  <label for="InterestedVolunteerNameInput">Name</label>
                  <input
                     className="u-full-width"
                     placeholder="Το ονοματεπώνυμο σου"
                     id="InterestedVolunteerNameInput"
                     type="text"
                     name="name"
                     value={participantInfo.name}
                     onChange={handleChange}
                  />
                  <label
                     for="InterestedVolunteerMailInput"
                     style={{ marginTop: "10px" }}
                  >
                     Εmail
                  </label>
                  <input
                     className="u-full-width"
                     type="email"
                     placeholder="volunteer@mailbox.com"
                     id="InterestedVolunteerMailInput"
                     name="email"
                     value={participantInfo.email}
                     onChange={handleChange}
                  />
               </div>
               <label
                  for="InterestedVolunteerReasonsInput"
                  style={{ marginTop: "10px" }}
               >
                  Motivation
               </label>
               <textarea
                  className="u-full-width EmailBody-Input"
                  placeholder="Γράψε μας εδώ τον λόγο για τον οποία θα ήθελες να συμμετάσχεις"
                  id="InterestedVolunteerReasonsInput"
                  style={{ height: "100px" }}
                  name="reason"
                  value={participantInfo.reason}
                  onChange={handleChange}
               ></textarea>
               <input class="button-primary" type="submit" value="ΑΠΟΣΤΟΛΗ" />
               {CommunicationWithCognihub}
            </form>
         </div>
      </div>
   );
};

export default BecomeMember;
