import React from 'react';
import archimedes from '../../../../assets/partners/archimedes.png';
import careerinprogress from '../../../../assets/partners/careerinprogress.png';
import citycampus from '../../../../assets/partners/citycampus.png';
import corallia from '../../../../assets/partners/corallia.png';
import glossis from '../../../../assets/partners/glossis.png';
import unities from '../../../../assets/partners/unities.png';
import "./Partners.css";

const PartnerLogos = () => {
  return (
      <div className="partner-logos">
          <a href="https://citycampus.gr/"><img src={citycampus} alt="City Campus" style={{ margin: '0 20px' }}/></a>
          <a href="https://www.glossis.gr/"><img src={glossis} alt="GLOSSIS"/></a>
          <a href="https://uni-ties.gr/"><img src={unities} alt="University Opportunities"/></a>
      </div>
  );
}

export default PartnerLogos;