import React from "react";
import Facebook from "../../../../assets/facebook.svg";
import Instagram from "../../../../assets/instagram.svg";
import Spotify from "../../../../assets/spotify.svg";
import Youtube from "../../../../assets/youtube.svg";
import LinkedIn from "../../../../assets/linkedin.svg";
import "./Social.css";

const Social = () => {
   return (
      <React.Fragment>
         <div className="SocialIcons-Container">
            <a
               className="link"
               href="https://www.facebook.com/cognihubgr/"
               target="_blank"
               rel="noreferrer"
            >
               <img src={Facebook} alt="React Logo" />
            </a>
            <a
               className="link"
               href="https://www.instagram.com/cognihub/?hl=en"
               target="_blank"
               rel="noreferrer"
            >
               <img src={Instagram} alt="React Logo" />
            </a>
            <a
               className="link"
               href="https://www.youtube.com/channel/UCBD6NoYPTu_U7uVZX0L0R_Q"
               target="_blank"
               rel="noreferrer"
            >
               <img src={Youtube} alt="React Logo" />
            </a>
            <a
               className="link"
               href="https://open.spotify.com/show/4lkIcHgNzjQiGqlmfuPH2P"
               target="_blank"
               rel="noreferrer"
            >
               <img src={Spotify} alt="React Logo" />
            </a>
            <a
               className="link"
               href="https://www.linkedin.com/company/cognihub/"
               target="_blank"
               rel="noreferrer"
            >
               <img src={LinkedIn} alt="React Logo" />
            </a>
         </div>
      </React.Fragment>
   );
};

export default Social;
