import './Home.css';
import React from 'react';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import Events from './Components/Events/Events';
import { firestore } from '../../firebase';
import HelpUs from './Components/HelpUs/HelpUs';
import Introduction from './Components/Introduction/Introduction';
import Latest from './Components/Latest/Latest';
import Social from './Components/Social/Social';
import CustomParticles from './Components/CustomParticles';
import Partners from './Components/Partners/Partners';

class Home extends React.Component {
   constructor() {
      super();

      this.state = { events: null };
   }

   componentDidMount() {
      this.getEvents().then((eventsReceived) => {
         this.setState({ events: eventsReceived });
      });
   }

   getEvents = async () => {
      const eventsRef = collection(firestore, "events");

      const q = query(eventsRef, orderBy("date", "desc"), limit(4));

      const querySnapshot = await getDocs(q);

      let eventsReceived = [];
      querySnapshot.forEach((doc) => {
         let eventData = {
            id: doc.id,
            title: doc.data().title,
            image: doc.data().image,
            date: doc.data().date,
            description: doc.data().description,
            category: doc.data().category,
            sciences: doc.data().sciences,
         };

         eventsReceived.push(eventData);
      });

      return eventsReceived;
   };

   render() {
      return (
         <div className="Page-Container">
            <Introduction />
            <h2 style={{ marginTop: "80px" }}>Τελευταία νέα:</h2>
            <Latest />
            {this.state.events ? <Events events={this.state.events} /> : null}
            <HelpUs />
            <h2 style={{ marginTop: "80px" }}>Ακολούθησέ μας</h2>
            <Social />
            <CustomParticles />
            <h2 style={{ marginTop: "80px" }}>Partners</h2>
            <Partners /> 
         </div>
      );
   }
}

export default Home;
