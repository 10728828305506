import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { AuthContext } from "../../Authorization/AuthProvider";

import "./Login.css";

const Login = ({ history }) => {
   const handleLogin = useCallback(
      async (event) => {
         event.preventDefault();
         const { email, password } = event.target.elements;

         const auth = getAuth();

         try {
            await signInWithEmailAndPassword(auth, email.value, password.value);
            history.push("/admin");
         } catch (error) {
            console.log(error);
         }
      },
      [history]
   );

   const LoginPage = (
      <div className="Page-Container">
         <form className="Login-Container" onSubmit={handleLogin}>
            <h2>Συνδεθείτε</h2>
            <div>
               <label for="email">Admin Email</label>
               <input
                  className="u-full-width"
                  type="email"
                  placeholder="test@mailbox.com"
                  name="email"
               />
            </div>
            <div>
               <label for="password">Admin Password</label>
               <input
                  className="u-full-width"
                  type="password"
                  placeholder="Το όνομα σας"
                  name="password"
               />
            </div>
            <input class="button-primary" type="submit" value="ΣΥΝΔΕΣΗ" />
         </form>
      </div>
   );

   const authValue = useContext(AuthContext);

   if (authValue.userDataPresent) {
      if (authValue.user == null) {
         return LoginPage;
      } else {
         return <Redirect to="/admin" />;
      }
   } else {
      return LoginPage;
   }
};

export default withRouter(Login);
