import './Latest.css';
import React from 'react';
import { Link } from 'react-router-dom';
import MentorHubBanner from '../../../../assets/MentorHubBanner.png';
import MentorHubSquare from '../../../../assets/MentorHubSquare.png';
import OpenCallBanner from '../../../../assets/OpenCallBanner.png';
import OpenCallSquare from '../../../../assets/OpenCallSquare.png';
import { useWindowSize } from '../../../../useWindowSize';

function Latest() {

   const windowSize = useWindowSize()

   return (
      <div className='LatestContainer'>
         {
            windowSize.width > 1000 ?
               <>
                  <Link to="/mentorhub">
                     <img className={"LatestImage"} src={MentorHubBanner} />
                  </Link>
               </>
               :
               <>
                  <Link to="/mentorhub">
                     <img className={"LatestImage"} src={MentorHubSquare} />
                  </Link>
               </>
         }
      </div>
   );

}

export default Latest;
