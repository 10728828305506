import React from "react";
import { firestore } from "../../firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firebaseDateToString } from "../../helper";
import date from "../../assets/date.png";
import atom from "../../assets/atom.png";
import { Link } from "react-router-dom";
import "../../skeleton.css";
import "./Events.css";
import Tooltip from "../../Components/Tooltip/Tooltip";

class Events extends React.Component {
   constructor() {
      super();
      this.eventsRef = collection(firestore, "events");
      this.state = { currentType: null, events: [] };
   }

   componentDidMount() {
      const urlParams = new URLSearchParams(this.props.location.search);
      const eventType = urlParams.get("type");

      this.getEvents(eventType).then((events) => {
         this.setState({ currentType: eventType, events: events });
      });
   }

   getEvents = async (eventType) => {
      var getEventsQuery;
      if (eventType) {
         getEventsQuery = query(
            this.eventsRef,
            where("category", "==", eventType),
            orderBy("date", "desc")
         );
      } else {
         getEventsQuery = query(this.eventsRef, orderBy("date", "desc"));
      }

      const querySnapshot = await getDocs(getEventsQuery);

      let events = [];
      querySnapshot.forEach((doc) => {
         let eventData = {
            id: doc.id,
            title: doc.data().title,
            image: doc.data().image,
            date: doc.data().date,
            category: doc.data().category,
            description: doc.data().description,
            sciences: doc.data().sciences,
         };

         events.push(eventData);
      });

      return events;
   };

   handleInputChange = async (event) => {
      let eventType = event.target.value;

      if (eventType === "all") {
         this.props.history.push(`/events`);

         this.getEvents().then((events) => {
            this.setState({ currentType: "all", events: events });
         });

         return;
      }

      this.getEvents(eventType).then((events) => {
         this.setState({ currentType: eventType, events: events });
      });

      this.props.history.push(`/events?type=${eventType}`);
   };

   render() {
      let pageContent = null;
      if (this.state.events) {
         pageContent = (
            <div className="Page-Container">
               <form className="SearchEventsForm-Container">
                  <label for="eventCategoryInput">Κατηγορία Δράσης</label>
                  <select
                     style={{ width: "350px" }}
                     className="u-full-width SearchEvent-Dropdown"
                     id="eventCategoryInput"
                     onChange={this.handleInputChange}
                     name="category"
                     value={this.state.currentType}
                  >
                     <option value="all">Όλες οι δράσεις</option>
                     <option value="Ιδεοθύελλα">Ιδεοθύελλα</option>
                     <option value="After Graduation Discussion">
                        After Graduation Discussion
                     </option>
                     <option value="Ο Άνθρωπος πίσω από τον Επιστήμονα">
                        Ο Άνθρωπος πίσω από τον Επιστήμονα
                     </option>
                     <option value="Παρουσιάσεις φοιτητών και νέων ερευνητών">
                        Παρουσιάσεις φοιτητών και νέων ερευνητών
                     </option>
                  </select>
               </form>
               <p className="noteAboutEvents">
                  * Περισσότερα για τις δράσεις μας θα βρείτε στον{" "}
                  <Link
                     className="link"
                     to="/events_guide"
                     style={{ color: "purple" }}
                  >
                     Οδηγό Δράσεων
                  </Link>
               </p>
               <div className="Events-Container">
                  {this.state.events.map((eventData, index) => (
                     <Link className="link" to={`/events/${eventData.id}`}>
                        <div className="EventVertical-Card">
                           <div
                              className="PreviewEventVertical"
                              style={{
                                 backgroundImage: `url(${eventData.image})`,
                                 backgroundSize: "cover",
                              }}
                           />
                           <div className="EventData-Container">
                              <h4>{eventData.title}</h4>
                              <p
                                 style={{
                                    marginTop: "-10px",
                                 }}
                              >
                                 {eventData.description}
                              </p>
                              <div
                                 style={{
                                    marginTop: "-19px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "85%",
                                 }}
                              >
                                 <div style={{ display: "flex" }}>
                                    <img
                                       style={{
                                          paddingTop: "23px",
                                          marginRight: "10px",
                                       }}
                                       src={date}
                                       width={20}
                                       height={21}
                                       alt="Date"
                                    />
                                    <p
                                       style={{
                                          fontSize: "18px",
                                       }}
                                    >
                                       {firebaseDateToString(eventData.date)}
                                    </p>
                                 </div>

                                 <Tooltip
                                    title={eventData.sciences.join(", ")}
                                    position="left"
                                 >
                                    <span>
                                       <img
                                          src={atom}
                                          width={30}
                                          height={31}
                                          alt="Date"
                                       />
                                    </span>
                                 </Tooltip>
                              </div>
                           </div>
                        </div>
                     </Link>
                  ))}
               </div>
            </div>
         );
      }
      return pageContent;
   }
}

export default Events;
