import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

const GuardedRoute = ({ component: RouteComponent, ...rest }) => {
   const authValue = useContext(AuthContext);
   if (authValue.userDataPresent) {
      if (authValue.user == null) {
         return <Redirect to="/login" />;
      } else {
         return (
            <Route
               {...rest}
               render={(routeProps) => <RouteComponent {...routeProps} />}
            />
         );
      }
   } else {
      return <Redirect to="/admin" />;
   }
};

export default GuardedRoute;
