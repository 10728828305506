import React from "react";
import { useState } from "react";
import { send } from "emailjs-com";
import config from "../../config.json";
import Social from "../Home/Components/Social/Social";
import "./ContactUs.css";

const ContactUs = () => {
   const [toSend, setToSend] = useState({
      from_name: "",
      from_email: "",
      message: "",
      communicationWithCognihub: null,
   });

   const handleChange = (e) => {
      setToSend({ ...toSend, [e.target.name]: e.target.value });
   };

   const onSubmit = (e) => {
      e.preventDefault();
      send(
         config.emailService.serviceId,
         config.emailService.templateId,
         toSend,
         config.emailService.userId
      )
         .then((response) => {
            setToSend({ communicationWithCognihub: true });
         })
         .catch((err) => {
            console.log("FAILED...", err);
            setToSend({ communicationWithCognihub: false });
         });
   };

   var CommunicationWithCognihub;
   if (toSend.communicationWithCognihub == null) {
      CommunicationWithCognihub = null;
   } else if (toSend.communicationWithCognihub === false) {
      CommunicationWithCognihub = (
         <h3>Υπήρξε κάποιο πρόβλημα στην επικοινωνία. Παρακαλώ επικοινώνησε μαζί μας στο cognihub [at] gmail [dot] gr.</h3>
      );
   } else {
      CommunicationWithCognihub = (
         <h3>Ευχαριστούμε που επικοινωνήσατε μαζί μας!</h3>
      );
   }

   return (
      <div className="Page-Container">
         <form onSubmit={onSubmit} className="SendUsMail-Container">
            <h2> Επικοινωνία </h2>
            <p> Μη διστάσεις να επικοινωνήσεις μαζί μας για οποιαδήποτε πρόταση ή ερώτηση!<br />
            Μπορείς να στείλεις email στο cognihub [at] gmail [dot] gr ή να συμπληρώσεις την παρακάτω φόρμα:</p>
            <div className="EmailAndName-Container">
               <div>
                  <label for="from_email">Εmail</label>
                  <input
                     className="u-full-width"
                     type="email"
                     placeholder="test@mailbox.com"
                     name="from_email"
                     value={toSend.from_email}
                     onChange={handleChange}
                  />
               </div>
               <div>
                  <label for="from_name">Name</label>
                  <input
                     className="u-full-width"
                     type="text"
                     placeholder="Ονοματεπώνυμο"
                     name="from_name"
                     value={toSend.from_name}
                     onChange={handleChange}
                  />
               </div>
            </div>
            <label for="message">Message</label>
            <textarea
               className="u-full-width EmailBody-Input"
               placeholder="Μήνυμα"
               name="message"
               value={toSend.message}
               onChange={handleChange}
            ></textarea>
            <input class="button-primary" type="submit" value="ΑΠΟΣΤΟΛΗ" />
            {CommunicationWithCognihub}
         </form>
         <h3 style={{ marginTop: "30px" }}>
            Μπορείτε να μας βρείτε και στα social media:
         </h3>
         <Social />
      </div>
   );
};

export default ContactUs;
