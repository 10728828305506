const firebaseDateToString = (firebaseDate) => {
   var date = new Date(firebaseDate.seconds * 1000);
   return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
   );
};

const firebaseDateToHtmlDateFormat = (firebaseDate) => {
   var date = new Date(firebaseDate.seconds * 1000);
   return (
      date.getFullYear() + "-" + +(date.getMonth() + 1) + "-" + date.getDate()
   );
};

export { firebaseDateToString, firebaseDateToHtmlDateFormat };
