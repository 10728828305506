import React from "react";
import parse from "html-react-parser";
import Youtube from "../../assets/youtube.svg";
import Spotify from "../../assets/spotify.svg";
import Facebook from "../../assets/facebook.svg";
import Folder from "../../assets/folder.png";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { firebaseDateToString } from "../../helper";
import Chipset from "../../Components/Chipset/Chipset";
import "./Event.css";

class Event extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         eventData: null,
      };
   }

   getStreamingPlatformImg = (link) => {
      if (link.includes("youtu.be") || link.includes("youtube")) {
         return Youtube;
      }
      if (link.includes("spotify")) {
         return Spotify;
      }
      if (link.includes("fb.watch")) {
         return Facebook;
      }
   };

   getEvent = async () => {
      const docRef = doc(firestore, "events", this.props.match.params.eventid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
         let eventData = {
            category: docSnap.data().category,
            content: docSnap.data().content,
            date: docSnap.data().date,
            image: docSnap.data().image,
            title: docSnap.data().title,
            links: docSnap.data().links,
            resources: docSnap.data().resources,
            sciences: docSnap.data().sciences,
         };

         return eventData;
      } else {
         // doc.data() will be undefined in this case
         console.log("No such document!");
      }
   };

   componentDidMount() {
      this.getEvent().then((eventData) => {
         this.setState({ eventData: eventData });
      });
   }

   render() {
      let pageContent = null;
      if (this.state.eventData) {
         pageContent = (
            <div className="Page-Container">
               <div className="Event-Grid">
                  <div className="EventInfo">
                     <img src={this.state.eventData.image} alt="Upload here" />
                     <div className="EventMetadata">
                        {/* Date and Time and Category */}
                        <div className="FlexRowStart-Container">
                           <div className="Metadata-Row">
                              <h4> Type </h4>
                              <p>{this.state.eventData.category}</p>
                           </div>
                        </div>
                        {/* Watch it on and Resources */}
                        <div className="FlexRowStart-Container">
                           <div className="Metadata-Row">
                              <h4> Date & Time </h4>
                              <p>
                                 {firebaseDateToString(
                                    this.state.eventData.date
                                 )}
                              </p>
                           </div>
                           <div className="Metadata-Row">
                              <h4> Watch it on: </h4>
                              <div className="StreamingPlatfroms-Container">
                                 {this.state.eventData.links.map((link) => (
                                    <a
                                       href={link}
                                       target="_blank"
                                       rel="noreferrer"
                                    >
                                       <img
                                          style={{
                                             width: "36px",
                                             height: "36px",
                                             paddingTop: "10px",
                                          }}
                                          src={this.getStreamingPlatformImg(
                                             link
                                          )}
                                          alt="React Logo"
                                       />
                                    </a>
                                 ))}
                              </div>
                           </div>
                           {this.state.eventData.resources ? (
                              <div className="Metadata-Row">
                                 <h4>Resources:</h4>
                                 <a
                                    className="link"
                                    href={this.state.eventData.resources}
                                    target="_blank"
                                    rel="noreferrer"
                                 >
                                    <img
                                       style={{
                                          width: "36px",
                                          height: "36px",
                                       }}
                                       src={Folder}
                                       alt="Resources"
                                    />
                                 </a>
                              </div>
                           ) : null}
                        </div>
                        {/* Sciences */}
                        <div
                           style={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              marginBottom: "20px",
                           }}
                        >
                           {this.state.eventData.sciences.map((science) => (
                              <Chipset science={science} />
                           ))}
                        </div>
                     </div>
                  </div>
                  <div className="EventDetails">
                     <h1>{this.state.eventData.title}</h1>
                     {parse(this.state.eventData.content)}
                  </div>
               </div>
            </div>
         );
      }
      return pageContent;
   }
}

export default Event;
