import './AboutUs.css';
import React from 'react';
import { Link } from 'react-router-dom';
import All from '../../assets/all_events.svg';
import Collab from '../../assets/idea.svg';
import Manual from '../../assets/events_manual.svg';
import Members from './Components/Members/Members';

const AboutUs = () => {
   return (
      <div className="Page-Container">
         <h2 style={{ marginTop: "80px", marginBottom: "20px" }}>
            Λίγα λόγια για εμάς
         </h2>
         <p className="fewWords">
            Το CogniHub είναι μία εθελοντική ομάδα με στόχο την ενδυνάμωση νέων ατόμων στα δυναμικά πεδία της Πληροφορικής, Ψυχολογίας, Γλωσσολογίας, Βιολογίας και Φιλοσοφίας. Μέσω δραστηριοτήτων σκοπεύουμε να συμβάλλουμε στην καλλιέργεια της διεπιστημονικής σκέψης και στην γεφύρωση του χάσματος μεταξύ του ακαδημαϊκού τομέα και της αγοράς εργασίας στην Ελλάδα.<br /><br />
            Οι <strong>δραστηριότητές</strong> μας περιλαμβάνουν: <br /><br />
            <ul>
               <li>Συζητήσεις & συνεντεύξεις</li>
               <li>Podcasts</li>
               <li>Workshops</li>
               <li>Εκπαιδευτικά βίντεο</li>
               <li>Συνεδρίες mentoring</li>
               <li>Παρουσιάσεις ερευνητικών εργασιών</li>
            </ul>

            Οι <strong>στόχοι</strong> του CogniHub στο σε σχέση με το Ελληνικό ακαδημαϊκό και εργασιακό περιβάλλον είναι: <br /><br />

            <ul>
               <li>Η ενίσχυση των δεξιοτήτων φοιτητών και νέων ατόμων σε σχέση με τους παραπάνω τομείς.</li>
               <li>Η παροχή ερεθισμάτων και ώθησης με στόχο την καθοδήγηση σε μια διεπιστημονική καριέρα.</li>
               <li>Η δημιουργία μιας πλατφόρμας με στόχο την ανάδειξη νέων επιστημών, καθώς και ανθρώπων που εργάζονται στον ευρύτερο χώρο της γνωσιακής επιστήμης.</li>
               <li>Η δικτύωση και η δημιουργία ενός advisory board.</li>
               <li>Η καλλιέργεια μιας διεπιστημονικής κοινότητας με απώτερο σκοπό την συνεχή ανταλλαγή ιδεών και γνώσης.</li>
               <li>Η δημιουργία μιας υγιούς κοινότητας ανθρώπων που αγαπούν τη γνωσιακή επιστήμη και τη διεπιστημονικότητα.</li>
            </ul>

         </p>
         <h2
            style={{
               marginTop: "50px",
               marginBottom: "40px",
               padding: "50px",
               textAlign: "center",
            }}
         >
            Το CogniHub μέσα από τις δράσεις μας
         </h2>
         <div className="CognihubEvents-Container">
            <div className="CognihubEventsTextAndActions-Container">
               <p>
                  Είμαστε πάντα ανοιχτοί σε συνεργασίες ή σε πιθανές
                  προτάσεις σας. Περισσότερες πληροφορίες σχετικά με τις δράσεις μπορείτε να βρείτε παρακάτω:
               </p>
               <div className="CognihubEventsActions-Container">
                  <Link className="link" to="/cooperate">
                     <div className="throughEventsAction">
                        <img
                           src={Collab}
                           style={{ width: "60px" }}
                           alt="Collaborate"
                        />
                        <p>Ας συνεργαστούμε</p>
                     </div>
                  </Link>
                  <Link className="link" to="/events_guide">
                     <div className="throughEventsAction">
                        <img
                           src={Manual}
                           style={{ width: "60px" }}
                           alt="Events Manual"
                        />
                        <p>Οδηγός Δράσεων</p>
                     </div>
                  </Link>
                  <Link className="link" to="/events">
                     <div className="throughEventsAction">
                        <img
                           src={All}
                           style={{ width: "60px" }}
                           alt="All Events"
                        />
                        <p>Όλες οι δράσεις</p>
                     </div>
                  </Link>
               </div>
            </div>
            <div className="Event-Examples-Collage">
               <div className="event-1"></div>
               <div className="event-2"></div>
               <div className="event-3"></div>
               <div className="event-4"></div>
            </div>
         </div>
         <h2 style={{ marginTop: "80px", marginBottom: "40px" }}>
            Η ομάδα μας
         </h2>
         <p className="fewWords">
            Η ομάδα μας απαρτίζεται από ανθρώπους πολλών διαφορετικών τομέων και κατευθύνσεων.
            <br />
            <br />
            Αν ενδιαφέρεσαι να γίνεις μέλος{" "}
            <Link
               style={{ color: "purple", textDecoration: "none" }}
               to="/becomemember"
            >
               συμπλήρωσε την φόρμα
            </Link>
            !
         </p>
         <h3 style={{ marginTop: "20px", marginBottom: "50px" }}>
            Γνώρισε τους εθελοντές μας
         </h3>
         <Members />
      </div>
   );
};

export default AboutUs;
