import React from "react";
import { Link } from "react-router-dom";
import "./CollabInfo.css";

const CollabInfo = () => {
   return (
      <div className="Collab-Container">
         <p>
            Η καρδιά του cognihub είναι οι δράσεις. Για αυτό τον λόγο είμαστε
            ανοιχτοί σε οποιαδήποτε μορφή συνεργασίας. Η διαδικασία είναι η
            εξής:
         </p>
         <div className="steps">
            <div className="step">
               <h4>Βήμα 1</h4>
               <p>
                  Έχετε κάποια ιδέα; Ανήκετε σε κάποια από τις παρακάτω
                  κατηγορίες; Τότε πατήστε το κουμπί “ενδιαφέρομαι”, για να
                  έρθετε σε επαφή μαζί μας, συμπληρώνοντας μία φόρμα που θα σας
                  κατευθύνει στη σύνταξη της ιδέας σας.
               </p>
            </div>
            <div className="step">
               <h4>Βήμα 2</h4>
               <p>
                  Σύντομα θα έρθουμε σε επαφή μαζί σας, για να συζητήσουμε τις
                  λεπτομέρειες υλοποίησης της ιδέας σας. Θα σας προτείνουμε
                  μορφές δράσεων, καθώς και πιθανές ημερομηνίες διεξαγωγής.
               </p>
            </div>
            <div className="step">
               <h4>Βήμα 3</h4>
               <p>
                  Από εκεί και πέρα, αφήστε όλες τις λεπτομέρειες υλοποίησης
                  πάνω μας. Εσείς αφοσιωθείτε στην ιδέα σας και στο περιεχόμενο
                  αυτής. Με μια σχετική προετοιμασία πριν την δράση, θα είμαστε
                  έτοιμοι για την πραγματοποίησή της.
               </p>
            </div>
         </div>
         <p style={{ marginTop: "35px" }}>
            Μπορείτε να{" "}
            <Link
               className="link"
               to="/events_guide"
               style={{ color: "purple" }}
            >
               μάθετε περισσότερα για τις τρέχουσες κατηγορίες δράσεων
            </Link>
            . Φυσικά, αν έχετε κάποια άλλη ιδέα, θα χαρούμε να την ακούσουμε.
         </p>
      </div>
   );
};

export default CollabInfo;
